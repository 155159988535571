.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  &_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    &_buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.widget {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 1px 1px #c3c3c3;
  border-radius: 8px;
  padding: 5px 0;
  &_header {
    display: flex;
    justify-content: space-between;
    &_left {
      width: 33.33%;
      padding-left: 10px;
      span {
        cursor: pointer;
      }
    }
    &_middle {
      width: 33.33%;
      display: flex;
      justify-content: center;
      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #6b6a6a;
      }
    }
    &_right {
      width: 33.33%;
      padding-right: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: end;
      &_button {
        width: 100%;
      }
    }
  }
  &_main {
    display: flex;
    flex-direction: column;
    height: 93%;
    width: 100%;
    align-items: center;

    &_container {
      width: 100%;
      overflow-y: auto;
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    &_suggestions {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      width: 85%;
      overflow-y: auto;
      flex-direction: column;
      ::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
      }
      .qanda {
        margin-bottom: 1.5vw;
      }
      .qanda:last-child {
        margin-bottom: 0vw;
      }
      .question {
        font-size: 1.2vw;
        font-weight: 500;
        margin-bottom: 0.3vw;
      }
      .answer {
        font-size: 0.9vw;
        color: #575757;
      }
      .tempdata {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &_box1 {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-left: 5%;

        h5 {
          font-size: 16px;
          font-weight: 600;
          text-align: right;
          color: #575757;
          padding-bottom: 5px;
          border-radius: 8px;
          border: 1px solid #b1a9a9;
          margin: 0 0 14px 0;
          padding: 10px;
        }
      }
      &_box2 {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        margin: 0 0 14px 0;
        padding: 10px;
        width: 95%;

        p {
          line-height: normal;
          font-size: 14px;
          font-weight: 400;
          color: #575757;
        }
      }
    }
    .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    &_message {
      width: 90%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      textarea {
        flex-grow: 1;
        width: 100%;
        height: 100px;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 5px 50px;
        height: 70px !important;
        font-size: 18px;
        word-wrap: break-word;
        overflow-y: auto;
        color: #6b6a6a;
      }
      textarea:focus-visible {
        outline: none !important;
      }
      .chatbotmsgimg {
        position: absolute;
        left: 0.8rem;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .chatbotsendimg {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        .send {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.code-block {
  background-color: #0d0d0d;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
  font-family: "Courier New", Courier, monospace;
}
pre {
  border-radius: 8px !important;
  margin: 15px 0 !important;
}
p {
  margin: 0;
  padding: 5px 0;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .chatbot {
    &_header {
      &_right {
        display: flex;
        justify-content: center;
      }
    }
    &_main {
      &_suggestions {
        flex-direction: column;
        width: 85%;
        & .tempdata {
          flex-direction: column;
        }
        &_box {
          width: 100%;
          border-radius: 8px;
          border: 1px solid #b1a9a9;
          padding: 1rem;
          h5 {
            font-size: 18px;
            font-weight: 600;
            color: #716666;
            padding-bottom: 10px;
          }
          p {
            line-height: normal;
            font-size: 16px;
            font-weight: 400;
            color: #716666;
          }
        }
      }
      &_message {
        width: 85%;
        textarea {
          width: 100%;
          font-size: 18px;
        }
      }
    }
    &_poweredby {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
      p {
        font-size: 10px;
        a {
          font-weight: bold;
        }
      }
    }
  }
}
